<template>
  <div class="app-modal" :class="{ active: showModal }">
    <transition name="slide-down">
      <div v-if="showModal" class="app-modal__dialog">
        <div class="d-flex justify-content-center">
          <div
            class="d-flex justify-content-center align-items-center app-modal__dialog__header"
          >
            <button class="btn app-modal__close" @click="changeShowModal()">
              <img
                :src="require('@/assets/image/close.svg')"
                alt="close popup"
              />
            </button>
            <p class="f-w6 app-modal__tilte">お問い合わせフォーム</p>
          </div>
        </div>
        <div class="content p-3">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.prevent="handleSubmit(onSubmit)"
              class="form text-left"
            >
              <div class="pt-3">
                <p class="f-w6">
                  お相手の申告に誤りがある点について
                  <span class="error">*</span>
                </p>
                <ValidationProvider
                  name="理由"
                  rules="required|max:1000"
                  v-slot="{ errors }"
                >
                  <b-form-textarea
                    class="f-w3 self-introduce"
                    placeholder="出来るだけ詳細にお願い致します。
                    例）向こうから謝罪があり午後から打ちっぱなしに行ったが、ラウンドにはいけていません。等"
                    v-model="reason"
                    rows="4"
                    no-resize
                  />
                  <span class="error f-w3 fs-14">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="f-w3 fs-12 mt-2 text-left">
                ※合流しラウンドが完了している場合はこちらのフォームを利用しないでください。
              </div>
              <div class="d-flex justify-content-center">
                <div class="img-preview mt-3 mb-3">
                  <img
                    v-if="previewUrl"
                    @click="closePreview"
                    src="@/assets/image/close-img.svg"
                    class="icon-close-img"
                    alt=""
                  />
                  <img v-if="previewUrl" :src="previewUrl" />
                </div>
              </div>
              <label
                class="
                  d-flex
                  justify-content-left
                  label--top
                  f-maru
                "
              >
                <b-form-file
                  class="d-none"
                  ref="form-file"
                  v-model="file"
                  v-on:change="chooseImage($event)"
                  accept="image/jpeg, image/png, image/jpg"
                />
                <div class="text-left label-upload f-w3">
                  <img
                    src="@/assets/image/img-contact.svg"
                    class="icon"
                    alt=""
                  />
                  ライブラリから選択
                </div>
              </label>
              <div class="f-w3 fs-14 text-left error mt-3">
                *チャットのスクショがありましたら、添付してください
              </div>
              <div class="w-100 mt-4 text-center">
                <button class="btn-submit f-w3" type="submit">
                  送信
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ContactModal",
  props: {
    showModal: {
      type: Boolean
    },
    message: {
      default: {}
    }
  },
  data() {
    return {
      reason: "",
      file: null,
      previewUrl: ""
    };
  },
  watch: {
    showModal: function() {
      if (!this.showModal) {
        this.reason = "";
        this.file = null;
        this.previewUrl = "";
        this.$refs.observer.reset();
      }
    }
  },
  methods: {
    changeShowModal() {
      this.$emit("change-show-contact", false);
    },
    chooseImage(e) {
      const file = e.target.files[0];
      this.previewUrl = URL.createObjectURL(file);
    },
    closePreview() {
      this.previewUrl = "";
      this.file = null;
    },
    onSubmit() {
      this.$emit("contact-report", {
        reason: this.reason,
        file: this.file || "",
        message: this.message
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_fontFamily.scss";
@import "@/assets/scss/_variables.scss";

.app-modal {
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 9000;
  max-width: 1080px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  left: 50%;
  transform: translateX(-50%);
  &__close {
    outline: none;
    box-shadow: none;
  }
  &.active {
    visibility: visible;
  }

  &__dialog {
    overflow: auto;
    position: fixed;
    top: 0;
    bottom: 0;

    &::-webkit-scrollbar {
      display: none;
    }
    & {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
    }
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    &__header {
      padding: 9px 0;
      top: 0;
      max-width: 1080px;
      width: 100%;
      background-color: #ffffff;
      z-index: 100;
      border-bottom: 1px solid #d9d9d9;
    }
    &--content {
      background-color: #ffffff;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      max-width: 1080px;
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      & {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
      }
    }
    .btn-submit {
      width: 100%;
      background-color: #464d77;
      color: $white;
      outline: none;
      box-shadow: none;
      border: none;
      width: 100%;
      height: 45px;
      font-size: 16px;
      border-radius: 5px;
      margin-bottom: 100px;
    }
    .error {
      color: #f14e4e;
      font-weight: 400;
    }
    .label-upload {
      font-size: 14px;
      font-weight: 400;
      color: #404040;
    }
    .img-preview {
      width: fit-content;
      height: 175px;
      text-align: center;
      position: relative;
      img {
        max-height: 175px;
        max-width: 100%;
        border-radius: 5px;
      }
      .icon-close-img {
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
      }
    }
  }

  .app-modal__tilte {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
    color: #000000;
  }
  .app-modal__close {
    position: absolute;
    left: 20px;
    padding: 0;
    img {
      width: 14px;
      display: block;
    }
  }
  textarea {
    white-space: pre-line;
  }

  textarea::placeholder {
    color: #b1b1b1;
    opacity: 1;
  }
  textarea:focus {
    border-color: #ced4da;
    outline: 0;
    box-shadow: none;
  }
}

.slide-down {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(-20px);
  }

  &-enter-active,
  &-leave-active {
    transition: ease 0.5s;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (min-width: 1080px) {
  .app-modal {
    &__dialog {
      &__header {
        padding: 15px 0;
        // position: fixed;
        top: 0;
        width: 100%;
        background-color: #ffffff;
        z-index: 100;
        border-bottom: 1px solid #d9d9d9;
      }
      .app-modal__tilte {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 0;
        color: #000000;
      }
      .content {
        padding: 30px !important;
      }
      .img-preview {
        height: 300px;
        img {
          max-height: 300px;
        }
        .icon-close-img {
          right: 10px;
          top: 10px;
        }
      }
    }
    .app-modal__close {
      position: absolute;
      left: 35px;
      padding: 0;
      img {
        width: 18px;
        display: block;
      }
    }
    .btn-submit {
      font-size: 20px;
      height: 55px;
      max-width: 440px;
    }
  }
}
</style>
