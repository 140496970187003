<template>
  <div>
    <div
      class="message-system"
      v-if="boxClass === 'message-system'"
      :class="{ active: propsLength }"
    >
      <p
        :class="propsIsPC ? 'fs-16' : 'fs-13'"
        class="text-left f-w6"
        v-if="propsMessage.type === 3"
      >
        マッチングおめでとうございます！！<br />
        まずは<br />
        ・ゴルフ場の詳細<br />
        ・プレー開始時間の詳細<br />
        ・集合場所の詳細と確認<br />
        ・朝食や練習の有無<br />
        その他気になることをお互い質問をしていきましょう！<br />
        男性は女性のプレー代を必ずお支払いください。<br />
        女性は男性がプレー代をお支払いいただけるので、クラブハウスで買い物する際は、男性にお声かけください。<br />
        プレー中は、プレーファースト、「ファー！」のお声掛けを忘れずにお願いします。<br />
        くれぐれも相手が不快に思うような言動はお控えください。<br />
        楽しいラウンドになりますように。<br />
        日付:
        {{ $dayjs(propsAd.scheduled_meeting_datetime).format("YYYY/MM/DD") }}
        <br />
        時間: {{ $dayjs(propsAd.scheduled_meeting_datetime).format("HH:mm")
        }}<br />
        プラン: {{ propsAd.plan_name }}<br />
        人数: {{ propsAd.male_participants + propsAd.female_participants
        }}<br />
        ゴルフ場： {{ propsAd.golf_course }}
      </p>
      <p
        :class="propsIsPC ? 'fs-16' : 'fs-13'"
        class="text-center f-w6"
        v-if="propsMessage.type === 4"
      >
        Superオファーゲストユーザーのご都合により、ゴルフイベントがキャンセルされました。
      </p>
      <p
        :class="propsIsPC ? 'fs-16' : 'fs-13'"
        class="text-center f-w6"
        v-if="propsMessage.type === 9"
      >
        <span v-if="authUser.sex === 1">
          今回はやむを得ない事情によりキャンセルされる運びとなりました。ポイントの消費はございませんので、ご安心ください。またのご利用お待ちしております。
        </span>
        <span v-else>
          今回はやむを得ない事情によりキャンセルされる運びとなりました。大変申し訳ございませんが、コインの移動はございません。またのご利用お待ちしております。
        </span>
      </p>
      <p
        :class="propsIsPC ? 'fs-16' : 'fs-13'"
        class="text-center f-w6 _met"
        v-if="propsMessage.type === 5"
      >
        {{ propsMessage.user.nickname }}さんが合流しました。
      </p>
      <p
        :class="propsIsPC ? 'fs-16' : 'fs-13'"
        class="text-center f-w6 _met"
        v-if="propsMessage.type === 6"
      >
        {{ propsMessage.user.nickname }}さんが解散しました。
      </p>
    </div>
    <div
      v-if="
        propsMessage.type === MESSAGES_TYPE.CALENDAR &&
          propsMessage.from === authUser.sex
      "
      class="d-flex justify-content-center mess-calendar"
    >
      <DatePicker
        :attributes="attributes"
        locale="ja"
        title-position="left"
        @dayclick="onDayClick"
        color="gray"
        :value="days"
        :min-date="minDay"
        :max-date="maxDay"
      />
    </div>
    <div
      v-else-if="propsMessage.type !== MESSAGES_TYPE.CALENDAR"
      class="message-box d-flex align-items-end"
      :class="boxClass"
    >
      <router-link
        v-if="
          parseInt(propsMessage.userId.id) === authUser.user_id &&
            parseInt(propsMessage.userId.id) !== 0
        "
        :to="{
          name:
            parseInt(propsMessage.user.sex) === 1
              ? 'ProfileViewMyProfile'
              : 'CastMyPageProfile'
        }"
        class="avatar-box"
      >
        <AvatarBox
          :imgUrl="
            propsMessage.user
              ? propsMessage.user.imageUrl ||
                require('@/assets/image/default_user.png')
              : ''
          "
          imgSize="29px"
          :imgAlt="propsMessage.user ? propsMessage.user.nickname : ''"
        />
      </router-link>
      <router-link
        v-if="
          parseInt(propsMessage.userId.id) !== authUser.user_id &&
            parseInt(propsMessage.userId.id) !== 0
        "
        :to="{
          name:
            parseInt(propsMessage.user.sex) === 1
              ? 'MenProfile'
              : 'CastProfile',
          params: { id: propsMessage.userId.id }
        }"
        class="avatar-box"
      >
        <AvatarBox
          :imgUrl="
            propsMessage.user
              ? propsMessage.user.imageUrl ||
                require('@/assets/image/default_user.png')
              : ''
          "
          imgSize="29px"
          :imgAlt="propsMessage.user ? propsMessage.user.nickname : ''"
        />
      </router-link>
      <AvatarBox
        v-if="parseInt(propsMessage.userId.id) === 0"
        :imgUrl="
          propsMessage.user
            ? propsMessage.user.imageUrl ||
              require('@/assets/image/default_user.png')
            : ''
        "
        imgSize="29px"
        :imgAlt="propsMessage.user ? propsMessage.user.nickname : ''"
      />
      <div
        v-if="propsMessage.message"
        class="message-box__content"
        :class="propsMessage.type === 10 ? 'custom-block-type10' : ''"
      >
        <div
          class="d-block"
          :style="{
            height:
              propsMessage.type === 10 ? (propsIsPC ? '115px' : '90px') : 0
          }"
        >
          <p
            v-if="!propsMessage.isReport"
            v-html="propsMessage.message"
            class="d-inline-block f-w6 text-left position-relative mb-0"
            :class="[
              propsIsPC ? 'fs-16' : 'fs-13',
              propsMessage.type === 10 ? 'custom-mess-type10' : ''
            ]"
          ></p>
          <div
            v-else-if="!propsMessage.isContact"
            class="d-inline-block f-w6 text-left position-relative mb-0 d-content"
            :class="propsIsPC ? 'fs-16' : 'fs-13'"
          >
            <p class="p-0" v-html="propsMessage.message"></p>
            <div
              data-v-337936c9=""
              class="f-w3 check-box custom-control custom-checkbox d-flex"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                name="check-complain"
                :id="
                  'check-complain-' +
                    propsMessage.adId +
                    '-' +
                    (propsMessage.isMen
                      ? propsMessage.castId
                      : propsMessage.userIdMen)
                "
                value="1"
                :checked="propsMessage.isSubmit"
                :disabled="propsMessage.isSubmit"
                @change="changeCheckbox"
              />
              <label
                class="custom-control-label"
                :for="
                  'check-complain-' +
                    propsMessage.adId +
                    '-' +
                    (propsMessage.isMen
                      ? propsMessage.castId
                      : propsMessage.userIdMen)
                "
                >合流しました</label
              >
            </div>
            <button
              disabled
              @click="complainReport(propsMessage)"
              class="a-box mt-2 f-w6"
              :id="
                'btn-complain-' +
                  propsMessage.adId +
                  '-' +
                  (propsMessage.isMen
                    ? propsMessage.castId
                    : propsMessage.userIdMen)
              "
            >
              送信
            </button>
          </div>
          <div
            v-else
            class="d-inline-block f-w6 text-left position-relative mb-0 d-content"
            :class="propsIsPC ? 'fs-16' : 'fs-13'"
          >
            <p class="p-0" v-html="propsMessage.message"></p>
            <button
              :disabled="propsMessage.isSubmit"
              @click="showContact = true"
              class="a-box mt-2 f-w6"
            >
              運営に相談
            </button>
          </div>
        </div>
        <span class="hook" :hidden="propsMessage.type === 10"></span>
        <div
          class="custom-type10"
          v-if="propsMessage.type === 10 && !propsMessage.isCancel"
        >
          <div class="custom-control custom-radio text-left m-2">
            <input
              :id="'customRadio1' + propsMessage.adId"
              type="radio"
              :name="'customRadio' + propsMessage.adId"
              v-model="selectOption"
              value="delay"
              class="custom-control-input"
            />
            <label
              class="custom-control-label f-w6"
              :class="propsIsPC ? 'fs-16' : 'fs-12'"
              :for="'customRadio1' + propsMessage.adId"
              >今行く</label
            >
            <select
              class="f-w6 fs-14 custom-control sel-custom mb-3 mt-2"
              v-model="reasonSelected"
              :disabled="selectOption !== 'delay'"
            >
              <option
                v-for="(reason, index) of reasons"
                :value="reason"
                :key="index"
                class="f-w6 fs-14 font-weight-normal"
              >
                {{ reason }}
              </option>
            </select>
          </div>
          <div class="custom-control custom-radio text-left mb-3 ml-2 mr-2">
            <input
              :id="'customRadio2' + propsMessage.adId"
              type="radio"
              :name="'customRadio' + propsMessage.adId"
              v-model="selectOption"
              value="cancel"
              class="custom-control-input"
            />
            <label
              class="custom-control-label f-w6"
              :class="propsIsPC ? 'fs-16' : 'fs-12'"
              :for="'customRadio2' + propsMessage.adId"
              >キャンセル</label
            >
          </div>
          <div class="d-flex justify-content-center mb-3">
            <button
              class="btn-event"
              :class="propsIsPC ? 'fs-16' : 'fs-12'"
              @click="sendReason"
            >
              確定する
            </button>
          </div>
        </div>
      </div>
      <div v-if="propsMessage.isLike" class="message-box__gift">
        <img
          v-if="parseInt(propsMessage.user.sex) === 2"
          src="@/assets/image/icon-like-cast.svg"
          alt="like"
        />
        <img v-else src="@/assets/image/icon-like-men.svg" alt="like" />
      </div>
      <div
        class="message-box__gift"
        v-if="propsMessage.type === MESSAGES_TYPE.GIFT"
      >
        <img
          :src="propsMessage.imageUrl || require('@/assets/image/gift.svg')"
          alt="Gift"
        />
      </div>
      <div
        class="message-box__content msg-suggest"
        v-if="
          propsMessage.imageUrl &&
            propsMessage.type !== MESSAGES_TYPE.GIFT &&
            propsMessage.isSuggest == 1
        "
      >
        <img
          class="message-box__img"
          :src="
            propsMessage.imageUrl || require('@/assets/image/default_user.png')
          "
          @load="$emit('load-success')"
        />
      </div>
      <div
        class="message-box__content"
        v-if="
          propsMessage.imageUrl &&
            propsMessage.type !== MESSAGES_TYPE.GIFT &&
            propsMessage.isSuggest != 1
        "
        @click="showImage()"
      >
        <img
          class="message-box__img mw-100"
          :src="
            propsMessage.imageUrl || require('@/assets/image/default_user.png')
          "
          @load="$emit('load-success')"
        />
      </div>
      <ModalImageMessage :id="propsMessage.imageUrl" ref="image">
        <img
          :src="
            propsMessage.imageUrl || require('@/assets/image/default_user.png')
          "
          alt=""
          class="map-image"
        />
      </ModalImageMessage>
      <span class="f-w3 _time" :class="propsIsPC ? 'fs-10' : 'fs-8'">{{
        formatDateTime(propsMessage.createdAt)
      }}</span>
    </div>
    <pre
      v-if="propsMessage.type === MESSAGES_TYPE.GIFT && authUser.sex === 2"
      :class="propsIsPC ? 'fs-16' : 'fs-13'"
      class="noti-receiver-gift f-w6"
    >
        ギフトを頂きました。
      お礼の返事をしてあげましょう。
    </pre>
    <ContactModal
      :message="propsMessage"
      :showModal="showContact"
      @change-show-contact="showContact = false"
      @contact-report="sendContactReport"
    />
  </div>
</template>

<script>
import AvatarBox from "../Common/AvatarBox.vue";
import { urlify } from "../../utils/ui";
import { MESSAGES_TYPE } from "@/utils/const";
import { mapGetters } from "vuex";
import ModalImageMessage from "@/components/ModalImageMessage.vue";
import ContactModal from "@/components/Message/ContactModal.vue";
import { DatePicker } from "v-calendar";

export default {
  name: "MessageBox",
  components: {
    AvatarBox,
    ModalImageMessage,
    ContactModal,
    DatePicker
  },
  props: {
    boxClass: {
      type: String
    },
    propsMessage: {
      type: Object
    },
    propsIsPC: {
      type: Boolean,
      default: false
    },
    propsAd: {
      type: Object,
      default: null
    },
    propsLength: {
      type: Boolean,
      default: false
    },
    targetCalendar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      MESSAGES_TYPE,
      memberSent: null,
      message:
        "申し訳ありません。クレジットカードでの決済処理が失敗致しました。オーダーがキャンセルになりました。",
      reasonSelected: "向かっています",
      reasons: [
        "向かっています",
        "集合時間に１０分遅れます",
        "集合時間に２０分遅れます",
        "集合時間に３０分遅れます"
      ],
      selectOption: "delay",
      showContact: false,
      minDay: new Date(),
      maxDay: new Date(Date.now() + 86400000 * 44),
      days: []
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
      members: "firebase/members"
    }),
    dates() {
      return this.days.map(day => day.date);
    },
    attributes() {
      return this.dates.map(date => ({
        highlight: {
          color: "green",
          fillMode: "solid",
          style: {
            "z-index": 1
          }
        },
        dates: date
      }));
    }
  },
  mounted() {
    this.message = urlify(this.message, "詳細はこちら");

    // if (this.propsMessage.type === 5) {
    //   this.memberSent = this.members.find(
    //     item => parseInt(item.userId) === parseInt(this.propsMessage.userId.id)
    //   );
    // }

    // if (this.propsMessage.type === 6) {
    //   this.memberSent = this.members.find(
    //     item => parseInt(item.userId) === parseInt(this.propsMessage.userId.id)
    //   );
    // }
    let body = document.querySelector("body");
    body.addEventListener("click", e => {
      if (this.targetCalendar) {
        const target = e.target;
        const calendar = document.getElementsByClassName("vc-container")[0];
        if (calendar && !calendar.contains(target)) {
          this.$parent.$data.targetCalendar = false;
          this.$parent.$data.inputMsg = "";
        }
      }
    });
  },
  methods: {
    showImage() {
      this.$refs["image"].openModal();
    },

    formatDateTime(data) {
      data = data.toDate();
      let hour = data.getHours() < 10 ? "0" + data.getHours() : data.getHours();
      let minute =
        data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes();
      return hour + ":" + minute;
    },

    sendReason() {
      this.$root.$refs.loading.start();
      let messWomen = "";
      let messMen = "";
      if (this.selectOption === "delay") {
        messWomen = "お気をつけて";
        messMen = `${this.authUser.nickname}さんから連絡が取れました。<br>
        <span style="color: #F3705A">${this.reasonSelected}</span><br>
        と回答がありました。<br>
        もう少々お待ちください。`;
      } else if (this.selectOption === "cancel") {
        messWomen = "キャンセルを受け付けました";
        messMen = `女性の都合によりキャンセル<br>
          になりました。大変申し訳あ<br>
          りませんでした。<br>
          今回のマッチングでポイント<br>
          が消費されることはございま<br>
          せん。ご安心ください。`;
      }
      this.$store
        .dispatch("orderManagement/delayOrderWomen", {
          userIdWomen: this.authUser.user_id,
          userIdMen: this.propsMessage.userIdMen ?? "",
          userIds: this.propsMessage.userIds ?? "",
          adId: this.propsMessage.adId ?? "",
          roomMenId: this.propsMessage.roomMenId ?? "",
          roomWomenId: this.propsMessage.roomWomenId ?? "",
          status: this.selectOption,
          messageId: this.propsMessage.id,
          messageMen: messMen,
          messageWomen: messWomen,
          messageCacel: `女性の都合によりキャンセル<br>
          になりました。大変申し訳あ<br>
          りませんでした。<br>
          今回のマッチングでポイント<br>
          が消費されることはございま<br>
          せん。ご安心ください。`
        })
        .then(response => {
          if (response?.message) {
            this.$toast(response?.message, "通知", "danger");
            return;
          }
          this.updateIsCancelMess();
          this.$root.$refs.loading.finish();
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    updateIsCancelMess() {
      this.propsMessage.isCancel = 1;
    },
    complainReport(message) {
      const adId = message.adId;
      let params = {
        adId,
        userId: this.authUser.user_id,
        toUserId: message.isMen ? message.castId : message.userIdMen,
        reporterName: this.authUser.nickname
      };
      if (this.authUser.sex === 1) {
        params = {
          ...params,
          isMen: 1
        };
      }
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("orderManagement/complainReportOrder", params)
        .then(response => {
          this.$root.$refs.loading.finish();
          const checkboxId =
            "check-complain-" +
            message.adId +
            "-" +
            (message.isMen ? message.castId : message.userIdMen);
          const buttonId =
            "btn-complain-" +
            message.adId +
            "-" +
            (message.isMen ? message.castId : message.userIdMen);
          document.getElementById(buttonId).disabled = true;
          if (response?.message) {
            document.getElementById(checkboxId).checked = false;
            this.$toast(response?.message, "通知", "danger");
            return;
          }
          document.getElementById(checkboxId).disabled = true;
          this.$set(this.propsMessage, "isSubmit", 1);
          this.$store.dispatch("firebase/updateSubmitMessage", {
            roomId: message.roomId,
            messageId: message.id
          });
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    sendContactReport(data) {
      const adId = data.message.adId;
      let form = new FormData();
      form.append("image_path", data.file);
      form.append("reason", data.reason);
      form.append("adId", adId);
      form.append("userId", this.authUser.user_id);
      if (this.authUser.sex === 1) {
        form.append("toUserId", data.message.castId);
      } else {
        form.append("toUserId", data.message.userIdMen || "");
        form.append("isCast", 1);
      }
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("orderManagement/contactReportOrder", form)
        .then(response => {
          this.$root.$refs.loading.finish();
          if (response?.message) {
            this.$toast(response?.message, "通知", "danger");
            return;
          }
          this.$set(this.propsMessage, "isSubmit", 1);
          this.$store.dispatch("firebase/updateSubmitMessage", {
            roomId: data.message.roomId,
            messageId: data.message.id
          });
          this.showContact = false;
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    changeCheckbox(e) {
      const id = e.target.id.replace("check", "btn");
      if (e.target.checked) {
        document.getElementById(id).disabled = false;
      } else {
        document.getElementById(id).disabled = true;
      }
    },
    onDayClick(day) {
      if (
        this.$dayjs(day.id).format("YYYY-MM-DD") >=
          this.$dayjs(this.minDay).format("YYYY-MM-DD") &&
        this.$dayjs(day.id).format("YYYY-MM-DD") <=
          this.$dayjs(this.maxDay).format("YYYY-MM-DD")
      ) {
        const idx = this.days.findIndex(d => d.id === day.id);
        if (idx >= 0) {
          this.days.splice(idx, 1);
        } else if (this.days.length < 3) {
          this.days.push({
            id: day.id,
            date: day.date
          });
        }
        let mess = "";
        // this.days.splice(3, 1);
        this.days.forEach(date => {
          mess = mess + this.$dayjs(date.id).format("MM/DD") + "・";
        });
        if (mess != "") {
          mess = mess.slice(0, -1) + " はいかがですか？";
        }
        this.$emit("change-input-mess", mess);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixin.scss";
.message-box__content {
  word-break: break-word;
}
.noti-receiver-gift {
  color: #818181;
}
.message-system {
  margin: 0 39px;
  &.active {
    border-bottom: 1px dashed #9f9f9f;
    margin-bottom: 20px;
  }
  word-break: break-word;

  ._met {
    color: #6e6e6e;
  }
}
.map-image {
  max-width: 100%;
  max-height: 100vh;
  padding: 30px;
}
.btn-event {
  width: 155px;
  height: 40px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #464d77;
}
.sel-custom {
  height: calc(1.5em + 0.75rem + 5px);
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  background: #f5f5f5;
  font-weight: 400 !important;
  padding-left: 15px;
  padding-right: 10px;
}
.custom-block-type10 {
  max-width: 100% !important;
  width: calc(100% - 50px);
}
.custom-mess-type10 {
  width: max-content;
  min-width: 200px;
}
.custom-type10 {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  background-color: #ffffff;
  width: max-content;
  margin: 20px auto;
}

.msg-suggest {
  img {
    pointer-events: none;
    width: 225px;
  }
}

@media screen and (max-width: 480px) {
  .msg-suggest {
    img {
      width: 240px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 430px) {
  .msg-suggest {
    img {
      width: 215px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 428px) {
  .msg-suggest {
    img {
      width: 214px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 414px) {
  .msg-suggest {
    img {
      width: 207px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 412px) {
  .msg-suggest {
    img {
      width: 205px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 393px) {
  .msg-suggest {
    img {
      width: 196px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 390px) {
  .msg-suggest {
    img {
      width: 195px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 384px) {
  .msg-suggest {
    img {
      width: 192px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 375px) {
  .msg-suggest {
    img {
      width: 187px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 360px) {
  .msg-suggest {
    img {
      width: 180px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 320px) {
  .msg-suggest {
    img {
      width: 160px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 285px) {
  .msg-suggest {
    img {
      width: 140px !important;
      pointer-events: none;
    }
  }
}

.message-box {
  // &:not(:last-child) {
  margin-bottom: 15px;
  // }

  .a-link {
    color: #000000;
  }
  &__content {
    max-width: calc(100vw * 2 / 3 + 20px);
    position: relative;
    color: #000000;
    margin-right: 10px;
    margin-left: 10px;

    .a-link {
      color: #000000;
    }

    p,
    .d-content {
      //word-break: break-word;
      border-radius: 20px;
      padding: 10px 13px;
      background-color: #ffffff;
      z-index: 2;
      color: #000;
      float: left;
      a {
        color: #000000 !important;
      }
    }

    @media screen and (min-width: 1200px) {
      max-width: 358px;

      p {
        padding: 15px 20px;
      }
      .custom-control-label {
        padding-top: 0 !important;
      }
    }

    img {
      border-radius: 20px;
    }

    .hook {
      display: block;
      position: absolute;
      z-index: -1px;
      width: 20px;
      height: 20px;
      border-radius: 0 0 10px 0;
      bottom: 2px;
      left: -10px;
      background-color: #ffffff;
      transform: rotate(15deg);
      &::before {
        content: "";
        position: absolute;
        width: 70%;
        height: 100%;
        border-radius: 0 0 10px 0;
        background-color: #f5f5f5;
        left: -1px;
        top: -1px;
      }
    }
  }

  &__gift {
    img {
      height: 120px;
      margin: 0 10px;
    }
  }

  ._time {
    color: #818181;
  }

  &.rtl {
    .message-box__gift {
      order: 2;
    }
    .hook {
      right: -10px;
      left: unset;
      transform: rotateX(15deg) rotateY(180deg);
      background-color: #464d77;
    }

    .message-box__content {
      p {
        background-color: #464d77;
        color: #ffffff;
        //word-break: break-word;

        a {
          color: #fff !important;
        }
      }

      &:after,
      &:before {
        left: unset;
        right: -10px;
      }

      &::before {
        background-color: #f5f5f5;
        border-radius: 0 0 0 10px;
      }

      &::after {
        border-radius: 0 0 0 20px;
      }
    }
    justify-content: flex-end;
    .avatar-box {
      order: 3;
    }

    .message-box__content {
      order: 2;
    }

    ._time {
      order: 1;
    }
  }
  .a-box:disabled {
    background: #b9b9b9;
  }
  .custom-control-input:checked ~ .custom-control-label:before {
    border-color: #464d77;
    background-color: #464d77;
    box-shadow: none;
    outline: none;
  }
  .custom-control-input:disabled ~ .custom-control-label:before {
    opacity: 0.5;
  }
  .custom-control-label {
    padding-top: 3px;
    cursor: pointer;
  }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #000;
  }
}
.mess-calendar {
  margin-bottom: 15px;
  position: relative;
  height: 272px;
  .date-calendar {
    position: absolute;
    top: -26px;
    /deep/input {
      border: 0;
      height: 0;
      width: 0;
      padding: 0;
    }
    /deep/i {
      display: none;
    }
  }
}
</style>
